import React, { useEffect, useState } from "react";
import AdminSidebar from "../components/AdminSidebar";
import Logo from "../assets/images/logo.png";
import LogoNew from "../assets/images/GROTrackTurquoise.png";
import Loading from "./Loading";
import { NavLink, useHistory, useParams } from "react-router-dom";
import dayjs from "dayjs";
import Swal from 'sweetalert2';
import LessThanIcon from '../assets/images/icons/LessThanIcon.svg'
import GreaterThanIcon from '../assets/images/icons/GreaterThanIcon.svg'
import LinkIcon from '../assets/images/icons/LinkIcon.svg'
import InfoIcon from '../assets/images/icons/InfoIcon.svg'
import { getHairComparisonPercentage } from "../utlis/calculations";

const ControlPanelImage = () => {
    const [message, setMessage] = useState("");
    const [success, setSuccess] = useState("");
    const [ready, setReady] = useState(false);
    const [image, setImage] = useState({});
    const [imageType, setImageType] = useState("");
    const [showImage, setShowImage] = useState("image");
    const [status, setStatus] = useState("");
    const [prevImage, setPrevImage] = useState({});
    const [resubmitReason, setResubmitReason] = useState("");
    // const [data, setData] = useState([])
    const Swal = require('sweetalert2')

    const { id } = useParams();
    const history = useHistory();
    const [invalidateClick, setInvalidateClick] = useState(false);
    const [resendClick, setResendClick] = useState(false);
    const [reasons, setReasons] = useState([
        { name: "Blurry Image", value: false, reason_for: 'invalidate', image_type: 'closeup' },
        { name: "Hair part line not clear", value: false, reason_for: 'invalidate', image_type: 'closeup' },
        { name: "Hair part line not centered", value: false, reason_for: 'invalidate', image_type: 'closeup' },
        { name: "Hair strands not combed", value: false, reason_for: 'invalidate', image_type: 'closeup' },
        { name: "Hair strands not flat", value: false, reason_for: 'invalidate', image_type: 'closeup' },
        { name: "Scalp position off", value: false, reason_for: 'invalidate', image_type: 'closeup' },
        { name: "Inconsistent with clinical Observation", value: false, reason_for: 'invalidate', image_type: 'closeup' },
        { name: "Other", value: false, reason_for: 'invalidate', image_type: 'closeup' },
        { name: "Blurry Image", value: false, reason_for: 'manual_annotation', image_type: 'closeup' },
        { name: "Gray or light color hairs", value: false, reason_for: 'manual_annotation', image_type: 'closeup' },
        { name: "Scalp marking interference", value: false, reason_for: 'manual_annotation', image_type: 'closeup' },
        { name: "Wrong Image Type", value: false, reason_for: 'invalidate', image_type: 'closeup' },
        { name: "Baseline image not valid", value: false, reason_for: 'invalidate', image_type: 'closeup' },
        { name: "Hair part line not straight", value: false, reason_for: 'invalidate', image_type: 'closeup' },
        { name: "Hair too short", value: false, reason_for: 'invalidate', image_type: 'closeup' },
        { name: "Blurry Image", value: false, reason_for: 'invalidate', image_type: '3x_closeup' },
        { name: "Hair part line not clear", value: false, reason_for: 'invalidate', image_type: '3x_closeup' },
        { name: "Hair part line not centered", value: false, reason_for: 'invalidate', image_type: '3x_closeup' },
        { name: "Hair strands not combed", value: false, reason_for: 'invalidate', image_type: '3x_closeup' },
        { name: "Hair strands not flat", value: false, reason_for: 'invalidate', image_type: '3x_closeup' },
        { name: "Scalp position off", value: false, reason_for: 'invalidate', image_type: '3x_closeup' },
        { name: "Inconsistent with clinical Observation", value: false, reason_for: 'invalidate', image_type: '3x_closeup' },
        { name: "Blurry Image", value: false, reason_for: 'manual_annotation', image_type: '3x_closeup' },
        { name: "Gray or light color hairs", value: false, reason_for: 'manual_annotation', image_type: '3x_closeup' },
        { name: "Scalp marking interference", value: false, reason_for: 'manual_annotation', image_type: '3x_closeup' },
        { name: "Hair strand occlusion", value: false, reason_for: 'manual_annotation', image_type: '3x_closeup' },
        { name: "Wrong Image Type", value: false, reason_for: 'invalidate', image_type: '3x_closeup' },
        { name: "Baseline image not valid", value: false, reason_for: 'invalidate', image_type: '3x_closeup' },
        { name: "Blurry Image", value: false, reason_for: 'invalidate', image_type: 'portrait' },
        { name: "Hair part line not clear", value: false, reason_for: 'invalidate', image_type: 'portrait' },
        { name: "Hair part line not centered", value: false, reason_for: 'invalidate', image_type: 'portrait' },
        { name: "Hair strands not combed", value: false, reason_for: 'invalidate', image_type: 'portrait' },
        { name: "Hair strands not flat", value: false, reason_for: 'invalidate', image_type: 'portrait' },
        { name: "Inconsistent with clinical Observation", value: false, reason_for: 'invalidate', image_type: 'portrait' },
        { name: "Blurry Image", value: false, reason_for: 'manual_annotation', image_type: 'portrait' },
        { name: "Gray or light color hairs", value: false, reason_for: 'manual_annotation', image_type: 'portrait' },
        { name: "Scalp marking interference", value: false, reason_for: 'manual_annotation', image_type: 'portrait' },
        { name: "Wrong Image Type", value: false, reason_for: 'invalidate', image_type: 'portrait' },
        { name: "Lightning Issue: Too Bright", value: false, reason_for: 'invalidate', image_type: 'portrait' },
        { name: "Lightning Issue: Too Dark", value: false, reason_for: 'invalidate', image_type: 'portrait' },
        { name: "Head Position Issue: Too Close", value: false, reason_for: 'invalidate', image_type: 'portrait' },
        { name: "Head Position Issue: Too Far", value: false, reason_for: 'invalidate', image_type: 'portrait' },
        { name: "Baseline image not valid", value: false, reason_for: 'invalidate', image_type: 'portrait' },
        { name: "Long hair, Hair not tied in the back", value: false, reason_for: 'invalidate', image_type: 'portrait' },
        { name: "Hair accessories interference", value: false, reason_for: 'invalidate', image_type: 'portrait' },
        { name: "Hair part line not straight", value: false, reason_for: 'invalidate', image_type: 'portrait' },
        { name: "Background not clear", value: false, reason_for: 'invalidate', image_type: 'portrait' },
        { name: "Head position off", value: false, reason_for: 'invalidate', image_type: 'portrait' },
        { name: "Hair not parted", value: false, reason_for: 'invalidate', image_type: 'portrait' },
    ]);
    const [selectedReasons, setSelectedReasons] = useState([]);
    const [images, setImages] = useState([]);
    const [patientId, setPatientId] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleChange = (reason) => {
        let updatedReasons = [...reasons];
        let updatedSelectedReasons = [...selectedReasons];
        let index = reasons.findIndex(r => r.name === reason.name);
        updatedReasons[index].value = !updatedSelectedReasons.includes(reason.name);
        if (updatedReasons[index].value) {
            updatedSelectedReasons = updatedSelectedReasons.concat(reason.name);
        }
        else {
            updatedSelectedReasons = updatedSelectedReasons.filter(r => r !== reason.name);
        }
        console.log(updatedSelectedReasons);
        setReasons(updatedReasons);
        setSelectedReasons(updatedSelectedReasons);
    }


    const getInvalidImage = () => {
        setReady(false);
        fetch(`${process.env.REACT_APP_API}/hair_analysis/invalid-image/${id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    console.log(response, "response")
                    setImageType(response.image_type);
                    setStatus(response.status);
                    setImages(response.images);
                    setPatientId(response.patient_id);
                    if (response.images.length > 1 && response.prevImage) {
                        setImage(response.image);
                        setPrevImage(response.prevImage);
                    } else {
                        setPrevImage(response.image);
                    }
                } else {
                    setMessage(response.message);
                }
            })
            .catch((error) => {
                console.error(error);
                setMessage("Some Error Occured. Please Try Again Later");
            }).finally(() => {
                setReady(true);
            });
    }

    const changeStatus = (status) => {
        Swal.fire({
            title: `Are you sure you want to ${status === 'approved' ? 'approve' : 'invalidate'} the image?`,
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                changeStatusApi(status);
            }
        })
    }

    const undoStatus = () => {
        Swal.fire({
            title: `Are you sure you want to undo the status?`,
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                setReady(false);
                setMessage("");
                fetch(`${process.env.REACT_APP_API}/hair_analysis/invalid-image/${id}/undo`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                    },
                })
                    .then(response => response.json())
                    .then(response => {
                        if (response.success) {
                            getInvalidImage();
                            // getPatientImages()
                        } else {
                            setMessage(response.message);
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        setMessage('Some Error Occured. Please Try Again Later');
                    })
                    .finally(() => {
                        setReady(true);
                    });
            }
        })
    }

    const changeStatusApi = (status) => {
        setReady(false);
        setMessage("");
        fetch(`${process.env.REACT_APP_API}/hair_analysis/invalid-image/${id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
            body: JSON.stringify({
                status: status,
                reasons: selectedReasons,
            }),
        })
            .then(response => response.json())
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Success!',
                        text: `The image was successfully ${status}`,
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    }).then(() => {
                        // history.push("/admin/control-panel/");
                        getInvalidImage();
                        // getPatientImages()
                    });
                } else {
                    setMessage(response.message);
                }
            })
            .catch(error => {
                console.error(error);
                setMessage('Some Error Occured. Please Try Again Later');
            })
            .finally(() => {
                setReady(true);
            });
    }

    const sendToAnnotators = () => {
        // Swal.fire({
        //     title: `Are you sure you want to send the image to the Annotators?`,
        //     text: "You won't be able to revert this!",
        //     icon: 'warning',
        //     showCancelButton: true,
        //     confirmButtonColor: '#3085d6',
        //     cancelButtonColor: '#d33',
        //     confirmButtonText: 'Yes'
        //   }).then((result) => {
        //     if (result.isConfirmed) {
        setReady(false);
        setMessage("");
        fetch(`${process.env.REACT_APP_API}/hair_analysis/invalid-image/${id}/resend`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
            body: JSON.stringify({
                reason: getImageValueByKey('status') === 'manual correction done' ? [resubmitReason] : selectedReasons,
            }),

        })
            .then(response => response.json())
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Success!',
                        text: `The image was successfully send to annotators.`,
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    }).then(() => {
                        // history.push("/admin/control-panel/");
                        getInvalidImage();
                        // getPatientImages()
                    });
                } else {
                    setMessage(response.message);
                }
            })
            .catch(error => {
                console.error(error);
                setMessage('Some Error Occured. Please Try Again Later');
            })
            .finally(() => {
                setReady(true);
            });
        //     }
        // })
    }

    useEffect(() => {
        if (id) {
            getInvalidImage();
        }
    }, [id]);


    const displayImage = (image) => {
        var paint = image.mainType === 'portrait' ? 6 : (image.mainType === 'closeup' ? 3 : 1)
        let ai_image = image?.ml_images?.find(r => r.paint == paint) || {};

        if (image?.image_path) {
            if (image.mainType === 'closeup') {
                var x = image?.scalp_position?.x_start;
                var y = image?.scalp_position?.x_end;

                return (
                    <div className="flex items-center justify-center h-96 mb-8">
                        <div className="mx-auto h-96 w-full">
                            <div className="relative flex justify-center h-96 w-full">
                                {showImage === 'ai' && ai_image?.image_path ? <img src={ai_image.image_path + "?width=286"} className="mb-4 h-96 cursor-pointer absolute z-10" style={{ left: x ? (x * 100) + '%' : undefined }} onClick={(e) => { e.preventDefault(); setShowImage(prev => prev === 'ai' ? 'image' : 'ai') }} /> : ""}
                                <img src={image.image_path + "?width=286"} className="w-full mb-4 h-96 cursor-pointer absolute object-cover z-0" onClick={(e) => { e.preventDefault(); setShowImage(prev => prev === 'ai' ? 'image' : 'ai') }} />
                            </div>
                        </div>
                    </div>
                );
            }
            var x = image?.scalp_position?.x_start;
            // var y = image?.scalp_position?.x_end;
            return (
                <div className="flex items-center justify-center h-96 mb-8">
                    <div className="mx-auto h-96 w-full">
                        <div className="relative flex justify-center h-96 w-full">
                            {showImage === 'ai' && ai_image?.image_path ? <img src={ai_image.image_path + "?width=286"} className="mb-4 h-96 cursor-pointer absolute z-10" style={{ left: x ? (x * 100) + '%' : undefined }} onClick={(e) => { e.preventDefault(); setShowImage(prev => prev === 'ai' ? 'image' : 'ai') }} /> : ""}
                            <img src={image.image_path + "?width=286"} className="w-full mb-4 h-96 cursor-pointer absolute object-cover z-0" onClick={(e) => { e.preventDefault(); setShowImage(prev => prev === 'ai' ? 'image' : 'ai') }} />
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="flex items-center justify-center h-96 mb-8">
                <div className="mx-auto h-96 w-full">
                    <div className="relative flex justify-center h-96 w-full">
                        <div className="flex justify-center items-center">
                            <p>No image found</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const printStatus = () => {
        if (getImageValueByKey('status') === 'invalid') {
            return 'review pending';
        }
        return getImageValueByKey('status');
    }

    const getImageValueByKey = (key) => {
        if (Object.keys(image).length > 0) {
            return image?.[key];
        } else {
            return prevImage?.[key];
        }
    }

    useEffect(() => {
        if (images.length) {
            const image = images.find(item => item.id === getImageValueByKey('id'));
            const index = images.indexOf(image);
            setCurrentIndex(index > 3 ? index - 3 : 0)
        }
    }, [images, image])

    return (
        <>
            <main
                className={`mx-auto relative w-full flex flex-col py-4 font-font-2 tracking-wider ${ready ? "" : "hidden"
                    }`}
                onClick={() => {
                    setInvalidateClick(false);
                    setResendClick(false);
                }}
            >
                <nav className="flex items-center border-b-2 border-background-2 pt-4 pb-6 mx-10">
                    <NavLink to="/">
                        <img className="h-12" src={LogoNew} alt="GRO Track" />
                    </NavLink>
                    <h1 className="mx-auto text-3xl text-text-2 font-bold text-white" style={{ transform: `translate(-40px)` }}>
                        IMAGE VALIDATION
                    </h1>
                </nav>
                {message && <p className="mt-8 text-center text-red-600">{message}</p>}
                {success && (
                    <p className="mt-8 text-center text-green-600">{success}</p>
                )}
                <section className="mt-7">

                    <div className="flex justify-evenly align-center mb-16">


                        <div className="relative">
                            <h2 className="text-xl text-gray-300 mb-3">{prevImage.patient} ({prevImage.clinic})</h2>
                            <h5 className="mb-2 mb-3 text-gray-400">{dayjs.unix(getImageValueByKey('hair_analysis_ts')).format("MM-DD-YY")}</h5>
                            <h5 className="mb-2 mb-3 text-gray-400">{imageType}</h5>
                            <h2 className="text-xl text-gray-300 capitalize mb-3">Status: {printStatus()}</h2>
                            {getImageValueByKey('status') === 'invalidated' &&
                                <p className="mb-2 mb-3 text-gray-400 font-light">
                                    {getImageValueByKey('reason').join(', ')}
                                </p>
                            }
                             <button
                            className="text-2xl focus:outline-none absolute -left-28 top-0"
                            onClick={function () {
                                history?.goBack()
                            }}
                        >
                            <img src={LessThanIcon} className='w-10 h-10' />
                        </button>
                        </div>
                        <div className="flex justify-center items-center" >
                            {(getImageValueByKey('status') === 'invalid' || getImageValueByKey('status') === 'manual correction done') && (
                                <div className="text-center flex flex-col">
                                    <div className="relative">
                                        <button
                                            type="button"
                                            className={`w-full px-8 py-1 bg-accent-1 rounded-lg text-text-3 font-bold cursor-pointer focus:outline-none mx-3 mb-3`}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                changeStatus("approved");
                                            }}
                                        >
                                            Approve
                                        </button>
                                    </div>
                                    {
                                        ((getImageValueByKey('status') !== 'manual correction done')) && (
                                            <div className="relative">
                                                <button
                                                    type="button"
                                                    className={`w-full px-8 py-1 bg-accent-1 rounded-lg text-text-3 font-bold cursor-pointer focus:outline-none mx-3 my-3`}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        // changeStatus("invalidated");
                                                        setSelectedReasons([]);
                                                        setInvalidateClick(true);
                                                        setResendClick(false);
                                                    }}
                                                >
                                                    Invalidate
                                                </button>
                                                {
                                                    invalidateClick &&
                                                    <div className="flex flex-col gap-3 bg-white w-64 p-4 rounded-lg z-10 absolute top-10 text-left h-64 overflow-y-scroll overflow-x-hidden custom-scrollbar" onClick={(e) => { e.stopPropagation(); }}>
                                                        {reasons.filter(reason => {
                                                            return reason.image_type === getImageValueByKey('mainType') && reason.reason_for === 'invalidate'
                                                        }).map((reason, i) => (
                                                            <div className="flex gap-2" key={i}>
                                                                <input type="checkbox" className="rounded  bg-accent-1" checked={selectedReasons.includes(reason.name)} value={reason.name} onChange={() => handleChange(reason)} />
                                                                <p className="text-sm font-thin">{reason.name}</p>
                                                            </div>
                                                        ))}
                                                        <button
                                                            type="button"
                                                            className={`w-full px-8 py-1 bg-accent-1 rounded-lg text-text-3 font-bold cursor-pointer focus:outline-none`}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                changeStatusApi("invalidated");
                                                                setInvalidateClick(false);
                                                                setResendClick(false);
                                                            }}
                                                        >
                                                            Confirm
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                        )
                                    }
                                    {
                                        getImageValueByKey('status') && (
                                            <div className="relative">
                                                <button
                                                    type="button"
                                                    className={`w-full px-8 py-1  ${status === 'pending manual validation' || status === "manual correction done" ? "bg-blue-500" : "bg-accent-1"}  rounded-lg text-text-3 font-bold cursor-pointer focus:outline-none mx-3 my-3 capitalize`}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        // sendToAnnotators();
                                                        setSelectedReasons([]);
                                                        setResendClick(true);
                                                        setInvalidateClick(false);
                                                    }}
                                                >
                                                    {status === 'pending manual validation' || status === "manual correction done" ? (status === "manual correction done" ? 'Resubmit' : status) : "Request Annotation"}
                                                </button>
                                                {
                                                    resendClick &&
                                                    <div className="flex flex-col gap-3 bg-white w-64 p-4 rounded-lg z-10 absolute top-10 text-left" onClick={(e) => { e.stopPropagation(); }}>
                                                        {status !== "manual correction done" && reasons.filter(reason => {
                                                            return reason.image_type === getImageValueByKey('mainType') && reason.reason_for === 'manual_annotation'
                                                        }).map((reason, i) => (
                                                            <div className="flex gap-2" key={i}>
                                                                <input type="checkbox" className="rounded  bg-accent-1" checked={selectedReasons.includes(reason.name)} onChange={() => handleChange(reason)} />
                                                                <p className="text-sm font-thin">{reason.name}</p>
                                                            </div>
                                                        ))}
                                                        {
                                                            <textarea className="border-2 outline-none" value={resubmitReason} onChange={(e) => { setResubmitReason(e.target.value) }}></textarea>
                                                        }
                                                        <button
                                                            type="button"
                                                            className={`w-full px-8 py-1 bg-accent-1 rounded-lg text-text-3 font-bold cursor-pointer focus:outline-none`}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                sendToAnnotators();
                                                                setInvalidateClick(false);
                                                                setResendClick(false);
                                                            }}
                                                        >
                                                            Confirm
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                        )
                                    }


                                </div>
                            )}
                            {(getImageValueByKey('status') !== 'invalid') && (
                                <div className="text-center flex flex-col">
                                    {
                                        ['invalidated', 'approved'].includes(status) && (
                                            <div className="relative">
                                                <button
                                                    type="button"
                                                    className={`w-full px-8 py-1 bg-red-500 rounded-lg text-text-3 font-bold cursor-pointer focus:outline-none mx-3 my-3`}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                        undoStatus();
                                                    }}
                                                >
                                                    Undo
                                                </button>
                                            </div>
                                        )
                                    }

                                </div>
                            )}
                        </div>
                    </div>
                    <div className="grid gap-6 grid-flow-col auto-cols-auto">
                        <div className={`grid gap-5 grid-flow-col auto-cols-auto items-center ${images.length > 4 ? `justify-evenly` : `justify-center`}`}>
                            {
                                images.length > 4 &&
                                <div className="w-10 flex align-center">
                                    <button
                                        className="text-2xl focus:outline-none"
                                        onClick={function () {
                                            if (currentIndex > 0) {
                                                setCurrentIndex(currentIndex - 1);
                                            }
                                        }}
                                    >
                                        <img src={LessThanIcon} className='w-10 h-10' />
                                    </button>
                                </div>
                            }

                            {
                                images.map((img, index) => {
                                    if (index === currentIndex || index === currentIndex + 1 || index === currentIndex + 2 || index === currentIndex + 3) {
                                        return (
                                            <div className={`text-center py-3 ${img.id === getImageValueByKey('id') ? 'border' : ''} `} style={{ width: '292px' }} key={img.id}>
                                                <div className="relative">

                                                    <div className="text-center">
                                                        <p className="mb-1 text-sm text-gray-400">{index === 0 ? 'Baseline' : 'Follow up'}</p>
                                                        <p className={`${img.status ? `mb-2` : `mb-9`} text-sm text-gray-400`}>
                                                            {
                                                                (img.mainType === 'portrait' || img.mainType === 'closeup') &&
                                                                <a href={`${process.env.REACT_APP_URL}/patient/presentation/overview/${patientId}?analysis_id=${img.hair_analysis_id}&mainType=${img.mainType}&subType=${img.subType}`} target="_blank">{dayjs.unix(img.hair_analysis_ts).format("MM-DD-YY")}</a>
                                                            }
                                                            {
                                                                (img.mainType === '3x_closeup') &&
                                                                <a href={`${process.env.REACT_APP_URL}/patient/presentation/hair-data/${patientId}?analysis_id=${img.hair_analysis_id}&mainType=${img.mainType}&subType=${img.subType}`} target="_blank">{dayjs.unix(img.hair_analysis_ts).format("MM-DD-YY")}</a>
                                                            }
                                                        </p>
                                                        <div className="flex justify-center items-center mb-2">
                                                            <p className={`text-sm mr-2 ${img.reason?.length || img.status === 'invalid' ? 'text-red-500' : 'text-green-400'}`}>{img.status ? img.status === 'invalid' ? "Review pending" : img.status.replace(img.status[0], img.status[0].toUpperCase()) : ""}</p>

                                                            {img.status ? (
                                                                // tooltip
                                                                <div className="relative group">
                                                                    <img src={InfoIcon} className="h-5 w-5 cursor-pointer" />
                                                                    <div className="absolute hidden top-6 left-1/2 z-10 flex flex-col items-center group-hover:flex" style={{ transform: 'translate(-50%, 0)' }}>
                                                                        <div className="w-4 h-4 bg-white" style={{ transform: "rotate(45deg)" }}></div>
                                                                        <div className="relative p-2 w-max bg-white text-center" style={{ transform: 'translate(0, -9px)' }}>
                                                                            {img.reason?.length ? (
                                                                                <p className='text-sm font-bold leading-none text-black whitespace-nowrap leading-normal'>{img.reason.map((item, index) => (<React.Fragment key={index}>{item}<br /></React.Fragment>))}</p>
                                                                            ) : (
                                                                                <p className='text-sm font-bold leading-none text-black whitespace-nowrap leading-normal px-2'>&mdash;</p>
                                                                            )}
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            ) : ("")}



                                                        </div>
                                                    </div>

                                                    {img.id !== getImageValueByKey('id') && img.invalid_id ?
                                                        <NavLink to={`/admin/control-panel/image/${img.invalid_id}`} >
                                                            <img src={LinkIcon} className='w-3 h-3 absolute top-0 right-0' />
                                                        </NavLink>
                                                        : ('')}

                                                </div>
                                                {displayImage(img)}
                                                <div>
                                                    {
                                                        (img.mainType === 'portrait' || img.mainType === 'closeup') && img.image_path &&
                                                        <p className={`mb-2 ${img.groIndex < images[index - 1]?.groIndex && img.id === getImageValueByKey('id') ? 'text-red-500' : 'text-gray-400'}`}>Gro Index: {img.groIndex}%</p>
                                                    }
                                                    {
                                                        img.mainType === '3x_closeup' && img?.groMeasure?.all &&
                                                        <>
                                                            <p className={`mb-2 ${img?.groMeasure?.all < images[index - 1]?.groMeasure?.all && img.id === getImageValueByKey('id') ? 'text-red-500' : 'text-gray-400'}`}>Hair Density: {img?.groMeasure?.all}/cm2 ({getHairComparisonPercentage(images[0]?.groMeasure?.all, img?.groMeasure?.all)}%)</p>
                                                            {/* ({img.groMeasure.density_change}%) */}
                                                            <p className={`mb-2 ${img?.groMeasure?.hairDiameter < images[index - 1]?.groMeasure?.hairDiameter && img.id === getImageValueByKey('id') ? 'text-red-500' : 'text-gray-400'}`}>Hair Thickness: {img?.groMeasure?.hairDiameter * 1000}µm ({getHairComparisonPercentage(images[0]?.groMeasure?.hairDiameter, img?.groMeasure?.hairDiameter)}%)</p>
                                                            {/* ({img.groMeasure.thickness_change}%) */}
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }

                                })
                            }
                            {
                                images.length === 1 && (
                                    <div style={{ width: "292px" }}>
                                        <div>
                                            <p className="mb-1 text-sm text-center">Follow up</p>
                                        </div>
                                        <div className="flex items-center h-full w-full justify-center">
                                            <p className="mb-2">No Follow Up</p>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                images.length > 4 &&
                                <div className="w-10 flex align-center">
                                    <button
                                        className="text-2xl focus:outline-none"
                                        onClick={function () {
                                            // if (currentIndex + 1 < images.length - 1) {
                                            if (currentIndex + 1 < images.length - 3) {
                                                setCurrentIndex(currentIndex + 1);
                                            }
                                        }}
                                    >
                                        <img src={GreaterThanIcon} className='w-10 h-10' />
                                    </button>
                                </div>
                            }
                        </div>


                    </div>


                </section>
            </main >
            <Loading message={"Loading Details"} ready={ready} />
        </>
    );
};


export default ControlPanelImage;
